import React from 'react';
import { Link } from "gatsby"
import './footer.css';

const Footer = ({props}) => (

  <footer className='footer'>
    © {new Date().getFullYear()} Leuven Lads <span className="separator">&nbsp;|</span> <Link to="/busreglement" className="footer-navLink" title="Busreglement">Busreglement</Link>
<Link to="/werkingsreglement" className="footer-navLink" title="Werkingsreglement">Werkingsreglement</Link>
  </footer>
)

export default Footer
