import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import PropTypes from "prop-types"
import React from "react"
import "./header.css"
import "./socialIcon.css"

const Header = ({ siteTitle }) => (
    <div className="header">
      <div className="header-left">
          <Link to="/" className="logoLink">
              <h1 className="logo">{ siteTitle }</h1>
          </Link>
          <div className="header-nav">
              <AnchorLink to="/#over" className="header-navLink" title="Over Ons" />
              <AnchorLink to="/#peters" className="header-navLink" title="Meter en Peter" />
              <Link to="/busreglement" className="header-navLink" title="Busreglement">Busreglement</Link>
              <Link to="/werkingsreglement" className="header-navLink" title="Werkingsreglement">Werkingsreglement</Link>
          </div>
      </div>
      <div className="header-right">
          <div className="header-social">
              <a href="https://www.facebook.com/leuvenlads" target="_blank" rel="noopener noreferrer" className="socialIcon socialIcon--facebook">Facebook</a>
              <a href="https://www.instagram.com/leuvenlads/" target="_blank" rel="noopener noreferrer" className="socialIcon socialIcon--instagram">Instagram</a>
          </div>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSfZirFm5qOobWsEd3xtzhtlcBRLaFYQEzDer6IkOOGRn7pyRA/viewform" target="_blank" rel="noopener noreferrer" className="header-btn">Word lid</a>
      </div>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
